import { Dispatch } from 'redux';

import {
  ProductAction,
  ProductActionTypes,
} from '../../types/entities/Product';
import { ApiUrl } from '../../types/ApiUrl';
import { $api } from '../../http';

interface queryProductParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  title?: string;
  sku?: string;
  producer?: string;
  categories?: any;
  condition?: number;
  price?: string;
  pointId?: string;
  status?: number;
}

export const fetchProducts = (queryParams: queryProductParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'nosort',
    id,
    title,
    sku,
    producer,
    categories,
    condition,
    price,
    pointId,
    status,
  } = queryParams;

  const urlProductParams = new URLSearchParams();
  urlProductParams.append('limit', limit);
  urlProductParams.append('page', page);
  urlProductParams.append('sortId', sortId);

  if (id !== undefined) urlProductParams.append('id', id);
  if (title !== undefined) urlProductParams.append('title', title);
  if (sku !== undefined) urlProductParams.append('sku', sku);
  if (producer !== undefined) urlProductParams.append('producer', producer);
  if (categories !== undefined)
    urlProductParams.append('categories', categories);
  if (condition !== undefined) urlProductParams.append('condition', String(condition));
  if (price !== undefined) urlProductParams.append('price', price);
  if (pointId !== undefined) urlProductParams.append('pointId', pointId);
  if (status !== undefined) urlProductParams.append('status', String(status));

  return async (dispatch: Dispatch<ProductAction>) => {
    try {
      dispatch({ type: ProductActionTypes.FETCH_PRODUCT });
      const response = await $api.get(
        `${ApiUrl.PRODUCT}?${urlProductParams.toString()}`,
      );
      dispatch({ type: ProductActionTypes.SET_PRODUCT_PAGE, payload: page });
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS,
        payload: response.data.items,
      });
      dispatch({
        type: ProductActionTypes.SET_TOTAL_PRODUCT_PAGE,
        payload: response.data.total,
      });
    } catch (e) {
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
        payload: 'Произошла ошибка при загрузке товаров',
      });
    }
  };
};
